import React,{useState,useEffect,useMemo} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import AddIcon from '@material-ui/icons/Add';
import {Tools} from './Tools';
import Hidden from '@material-ui/core/Hidden';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogHeader from './DialogHeader';
import ColInput from './ColInput';
//import Toast from './Toast';
import {Inc,Dataset,Stage,StageTicket,StageForm,StageSeat, StageBooking} from './Inc';
import {Theme} from './Theme';
//import Ajax from './Ajax';

interface Props {
	close():void,
	stage:Stage,
	booking?:StageBooking,
	update(booking:StageBooking):void,
	add(booking:StageBooking):void,
	isOpen:boolean,
}

const StageBookingInput = (props:Props)=> {
	const [booking,setBooking] = useState(Inc.defaultStageBooking());
	const [states,setStates] = useState({
		tickets:[] as Dataset[],
		seats:[] as Dataset[],
		forms:[] as Dataset[],
		quantitys:[] as Dataset[],
	})
	const [vali,setVali] = useState({
		errors:Inc.defaultStageBookingInputErrors(),
		message:"",
	});
	//const [toast,setToast] = useState("");
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));
	
	const change = (name:string,value:string)=>{
		if( name==="SeatId" ){
			const seatId = parseInt(value) ? parseInt(value) : -1;
			//console.log(seatId);
			if( seatId>0 ){
				let quantity = booking.Quantity;
				if( quantity===0 ){
					quantity=1;
				}
				setBooking({...booking,SeatId:seatId,Quantity:quantity});
			} else if(booking.Additionals.length>0){
				const newBooking = {...booking,SeatId:booking.Additionals[0].SeatId,Quantity:booking.Additionals[0].Quantity};
				newBooking.Additionals = [...booking.Additionals];
				newBooking.Additionals.shift();
				setBooking(newBooking);
				//setBooking({...booking,SeatId:booking.Additionals[0].SeatId,Quantity:booking.Additionals[0].Quantity});
			} else {
				setBooking({...booking,[name]:0});
			}
		} else if( name==="Quantity" ){
			let v = 0;
			if( parseInt(value) ){
				v = parseInt(value);
			}
			setBooking({...booking,[name]:v});
		} else if( name.substring(0,16)==="AdditionalSeatId" ){
			let no = parseInt(name.substring(16,17));
			const seatId = parseInt(value) ? parseInt(value) : -1;
			const newAdditionals = [...booking.Additionals];
			if( seatId>0 ){
				newAdditionals[no].SeatId = seatId;
			} else {
				newAdditionals.splice(no,1);
			}
			const newBooking = {...booking,Additionals:newAdditionals};
			setBooking(newBooking);
		} else if( name.substring(0,18)==="AdditionalQuantity" ){
			let no = parseInt(name.substring(18,19));
			const newAdditionals = [...booking.Additionals];
			newAdditionals[no].Quantity = parseInt(value);
			const newBooking = {...booking,Additionals:newAdditionals};
			setBooking(newBooking);
		} else if( name==="PaymentTypeId" && value==="9" ){
			setBooking({...booking,[name]:value,"Payment":""});
		} else if( name==="TicketCode" ){
			const newSeats = updateSeats(value,true);
			if( booking.SeatId===0 ){
				setBooking({...booking,[name]:value,SeatId:parseInt(newSeats[0].id),Quantity:1,Additionals:[]});
			} else {
				let seatId = 0;
				let quantity = 0;
				for( let i=0; i<newSeats.length; i++ ){
					if(parseInt(newSeats[i].id)===booking.SeatId){
						seatId = booking.SeatId;
						quantity = booking.Quantity;
						break;
					}
				}
				setBooking({...booking,[name]:value,SeatId:seatId,Quantity:quantity,Additionals:[],});
			}

			//const newSeats = updateSeats(value,true);
			//if( booking.SeatId===0 ){
			//	setBooking({...booking,[name]:value,SeatId:parseInt(newSeats[0].id),Quantity:1,Additionals:[]});
			//} else {
			//	let flag = false;
			//	for( let i=0; i<newSeats.length; i++ ){
			//		if(parseInt(newSeats[i].id)===booking.SeatId){
			//			flag = true;
			//		}
			//	}
			//	if( flag ){
			//		setBooking({...booking,[name]:value,Additionals:[],});
			//	} else {
			//		setBooking({...booking,[name]:value,SeatId:0,Quantity:0,Additionals:[],	});
			//	}
			//}
		} else {
			setBooking({...booking,[name]:value});
		}

		//if( name==="TicketCode" ){
		//	updateSeats(value,true);
		//}
	}

	const validate = ()=>{
		let errors = Inc.defaultStageBookingInputErrors();
		let flag = false;

		if( booking.TicketCode==="" ){
			errors.TicketCode = "選択して下さい";
			flag = true;
		}

		let seatIds = [];
		if( booking.SeatId===0 ){
			//errors.SeatId = "選択して下さい";
			errors.Additionals = "選択して下さい";
			flag = true;
		} else {
			if( !booking.Quantity || booking.Quantity===0 ){
				//errors.Quantity = "入力して下さい";
				errors.Additionals = "選択して下さい";
				flag = true;
			} else {
				//console.log(booking.Quantity);
			}
			let ex = false
			for( let i=0; i<states.seats.length; i++ ){
				if(states.seats[i].id===String(booking.SeatId)){
					ex = true;
				}
			}
			if(!ex){
				//errors.SeatId = "選択して下さい";
				errors.Additionals = "選択して下さい";
				flag = true;	
			} else {
				seatIds.push(booking.SeatId);
			}
		}
		if( booking.Additionals && booking.Additionals.length>0 ){
			for( let i=0; i<booking.Additionals.length; i++ ){
				if( booking.Additionals[i].SeatId===0 ){
					errors.Additionals = "選択して下さい";
					flag = true;
				} else if( booking.Additionals[i].SeatId>0 ) {
					if( booking.Additionals[i].Quantity===0 ){
						errors.Additionals = "選択して下さい";
						flag = true;
					}

					let ex = false
					for( let j=0; j<states.seats.length; j++ ){
						if(states.seats[j].id===String(booking.Additionals[i].SeatId)){
							ex = true;
						}
					}
					if(!ex){
						errors.Additionals = "選択して下さい";
						flag = true;	
					} else {
						if( seatIds.includes(booking.Additionals[i].SeatId) ){
							errors.Additionals = "同じ種類が選ばれています";
							flag = true;		
						} else {
							seatIds.push(booking.Additionals[i].SeatId);
						}
					}
				}
			}
		}

		if( booking.Serial.length>24 ){
			errors.Serial = "24文字以内にして下さい";
			flag = true;
		}

		if( booking.Name==="" ){
			errors.Name = "入力して下さい";
			flag = true;
		} else if( booking.Name.length>40 ){
			errors.Name = "40文字以内にして下さい";
			flag = true;
		}
		if( booking.Kana==="" ){
			errors.Kana = "入力して下さい";
			flag = true;
		} else if( booking.Kana.length>40 ){
			errors.Kana = "40文字以内にして下さい";
			flag = true;
		}
		if( booking.Email==="" ){
			//errors.Email = "入力して下さい";
			//flag = true;
		} else if( Tools.validateMail(booking.Email)===false ){
			errors.Email = "不正なメールアドレスです";
			flag = true;
		}
		if( booking.Email.length>100 ){
			errors.Email = "100文字以内にして下さい";
			flag = true;
		}
		if( props.stage.UseTel ){
			if( booking.Tel==="" ){
				//errors.Email = "入力して下さい";
				//flag = true;
			} else if( Tools.validateTel(booking.Tel)===false ){
				errors.Tel = "不正な電話番号です";
				flag = true;
			}
		}

		if( props.stage.UseEnq && booking.Contact==="" ){
			errors.Contact = "選択して下さい";
			flag = true;
		}

		if( booking.Payment==="" && booking.PaymentTypeId==="9" ){
			errors.Payment = "入力して下さい";
			flag = true;
		}

		if( flag ){
			setVali({errors:errors,message:"赤字部分を修正して下さい"});
			return false
		} else {
			return true
		}

	}

	const add = async ()=>{
		const result = validate();
		if( !result ){
			return;
		}

		let code = "";
		props.stage.Tickets.map((ticket:StageTicket,index:number)=>{
			if(ticket.Code.substr(0,12)===booking.TicketCode && ticket.Code.substr(11+booking.SeatId,1)==="1"){
				code = ticket.Code;
			}
			return true;
		});
		//console.log("code",code,booking);

		props.stage.Forms.map((form:StageForm,index:number)=>{
			if( form.Code === booking.FormCode ){
				booking.FormName = form.Name;
			}
			return true;
		});	

		props.add({...booking,EntryMethod:"A",TicketCode:code,StageId:props.stage.Id});
	}

	const update = async ()=>{
		const result = validate();
		if( !result ){
			return;
		}

		let code = "";
		props.stage.Tickets.map((ticket:StageTicket,index:number)=>{
			if(ticket.Code.substr(0,12)===booking.TicketCode && ticket.Code.substr(11+booking.SeatId,1)==="1"){
				code = ticket.Code;
			}
			return true;
		});

		props.stage.Forms.map((form:StageForm,index:number)=>{
			if( form.Code === booking.FormCode ){
				booking.FormName = form.Name;
			}
			return true;
		});	

		props.update({...booking,TicketCode:code});
	}

	//const closeToast = ()=>{
	//	setToast("");
	//}

	const updateSeats = (code:string,update:boolean)=>{
		let seats:Dataset[] = [];
		props.stage.Tickets.map((ticket:StageTicket,index1:number)=>{
			if( ticket.Code.substr(0,12)===code.substr(0,12) ){
				props.stage.Seats.map((seat:StageSeat,index2:number)=>{
					if( ticket.Code.substr(index2+12,1)==="1" ){
						let price = 0;
						if( seat.Price ){
							price = seat.Price;
						}
						seats.push({
							id: String(index2+1),
							label: Inc.seats[index2] + "　" + seat.Name + "（" + Tools.comma(price) + "円）",
						})
					}
					return true;
				});
			}
			return true;
		})
		seats.sort( (a:Dataset, b:Dataset)=>{
			if ( a.id>b.id ) {
				return 1;
			} else {
				return -1;
			}
		})

		if( update ){
			setStates({...states,seats:seats});
		}
		return seats;
	}

	const nextSeatId = useMemo(()=>{
		let seatId = -1;
		if( booking.Additionals && booking.Additionals.length<states.seats.length-1 ){
			for( let i=0; i<states.seats.length; i++ ){
				const id = parseInt(states.seats[i].id);
				let flag = true;
				if( seatId===-1 ){
					if( booking.SeatId===id ){
						flag=false;
					} else if( booking.Additionals && booking.Additionals.length>0 ){
						for( let j=0; j<booking.Additionals.length; j++ ){
							if( booking.Additionals[j].SeatId===id ){
								flag = false;
								break;
							}
						}
					}
					if( flag ){
						seatId = id;
						break;
					}
				}
			}
		}
		return seatId;
	},[states.seats,booking.SeatId,booking.Additionals]);

	const totalPrice = useMemo(()=>{
		let total = 0;
		for( let i=0; i<props.stage.Seats.length; i++ ){
			const seat = props.stage.Seats[i];
			const price = seat.Price ? seat.Price : 0;
			if( booking.SeatId===i+1 && booking.Quantity ){
				total += price * booking.Quantity;
			}
			if( booking.Additionals && booking.Additionals.length>0 ){
				for( let j=0; j<booking.Additionals.length; j++ ){
					if( booking.Additionals[j].SeatId===i+1 && booking.Additionals[j].Quantity>0 ){
						total += price * booking.Additionals[j].Quantity;
					}							
				}
			}
		}
		return total;
	},[props.stage.Seats,booking.SeatId,booking.Quantity,booking.Additionals]);

	const addLine = ()=>{
		//const nextSeatId = getNextSeatId;
		if( nextSeatId>-1 ){
			const newAdditionals = [...booking.Additionals];
			newAdditionals.push({
				SeatId: nextSeatId,
				Quantity: 1,
				Price: props.stage.Seats[nextSeatId-1].Price as number,
			});
			setBooking({...booking,Additionals:newAdditionals});
		}
	}


	useEffect(()=>{
		if( props.stage.Id!=="" ){
			if( props.booking ){
				//Tools.addTicketCode(props.booking,props.stage.doc);
				setBooking({...props.booking,TicketCode:props.booking.TicketCode.substr(0,12)});
			} else {
				setBooking(Inc.defaultStageBooking());
			}
			setVali({
				errors:Inc.defaultStageBookingInputErrors(),
				message:"",
			});

			let tickets:Dataset[] = [];
			let exists:{[key: string]: boolean} = {};
			let forms:Dataset[] = [];
			let quantitys:Dataset[] = [];

			props.stage.Tickets.map((ticket:StageTicket,index:number)=>{
				if( !exists[ ticket.Code.substr(0,12) ] ){
					exists[ ticket.Code.substr(0,12) ] = true;
					tickets.push({
						id: ticket.Code.substr(0,12),
						label: ticket.Label,
					})
				}
				return true;
			});

			props.stage.Forms.map((form:StageForm,index:number)=>{
				if( index>0 ){
					forms.push({
						id: form.Code,
						label: form.Name,
					})
				} else {
					forms.push({
						id: "",
						label: Inc.defaultFormName,
					})
				}
				return true;
			});

			for( let i=1 ; i<=Inc.maxQuantity; i++ ){
				quantitys.push({
					id: String(i),
					label: String(i)+"枚",
				})
			}

			let seats:Dataset[] = [];
			if( props.booking ){
				const tmp = updateSeats(props.booking.TicketCode,false);
				if( tmp ){
					seats = tmp;
				}
			}

			setStates({...states,tickets:tickets,seats:seats,forms:forms,quantitys:quantitys});
		}
	},[props.stage.Id,props.booking,props.isOpen]);

	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				if( reason!=="backdropClick" ){
					props.close();
				}
			}}
			fullScreen={fullScreen}
		>
				<DialogHeader close={props.close}/>
				<DialogContent dividers>
				<div className="confirmRoot">
					<h2>{props.stage.Title}</h2>
					<Grid container spacing={1} style={{marginBottom:"2em"}}>
						<Grid item xs={12} sm={3} className="formName">時間・回</Grid>
						<Grid item xs={12} sm={9}>
							<ColInput id="TicketCode" noName={true} dense={true} type="menu" error={vali.errors.TicketCode} name="時間・回" list={states.tickets} value={booking.TicketCode} change={change}/>
						</Grid>

						<Grid item xs={12} sm={3} className="formName">種類と枚数</Grid>
						<Grid item xs={9} sm={7}>
							<ColInput id="SeatId" noName={true} dense={true} type="menu" name="チケット種類" list={states.seats} value={String(booking.SeatId)} change={change}/>
						</Grid>
						<Grid item xs={3} sm={2}>
							<ColInput id="Quantity" noName={true} dense={true} type="menu" name="枚数" list={states.quantitys} value={String(booking.Quantity)} change={change}/>
						</Grid>

						{booking.Additionals && booking.Additionals.map((additional,index)=>{
							const key = "add"+index;
							const name1 = "AdditionalSeatId"+index;
							const name2 = "AdditionalQuantity"+index;
							return (
								<React.Fragment key={key}>
								<Hidden smDown>
								<Grid item xs={12} sm={3} className="formName">　</Grid>
								</Hidden>
								<Grid item xs={9} sm={7}>
									<ColInput id={name1} noName={true} dense={true} type="menu" name="チケット種類" list={states.seats} value={String(additional.SeatId)} change={change}/>
								</Grid>
								<Grid item xs={3} sm={2}>
									<ColInput id={name2} noName={true} dense={true} type="menu" name="枚数" list={states.quantitys} value={String(additional.Quantity)} change={change}/>
								</Grid>
								</React.Fragment>
							)
						})}

						{(vali.errors.Additionals!=='') &&
						<>
						<Hidden smDown>
							<Grid item xs={12} sm={3} className="formName">　</Grid>
						</Hidden>
						<Grid item xs={12} sm={9}>
							<FormHelperText style={{color:'#0000dd'}}>※{vali.errors.Additionals}</FormHelperText>
						</Grid>
						</>
						}
						
						<Grid item xs={6} className="formValue">
						{!props.booking &&
							<Button variant="outlined" startIcon={<AddIcon/>} onClick={addLine} disabled={booking.SeatId===0 || nextSeatId===-1}>種類を追加</Button>
						}
						</Grid>
						
						<Grid item xs={6} className="formValue" style={{textAlign:'right',marginTop:'0.9em'}}>
							合計金額　
							{booking.Quantity && booking.SeatId>0 && booking.TicketCode!=="" ? (
							<span>{Tools.comma(totalPrice)}円</span>
							):(
							<span>---- 円</span>
							)}
						</Grid>
						<Grid item xs={12}><Divider style={{marginBottom:'0.5em'}}/></Grid>
						{props.stage.UseNumber &&
						<React.Fragment>
						<Grid item xs={12} sm={3} className="formName">整理番号</Grid>
						<Grid item xs={12} sm={9}>
							<ColInput id="Serial" noName={true} dense={true} error={vali.errors.Serial} type="text" name="整理番号" placeholder="24文字以内" helper="自動発行する場合は空欄にしてください　" value={booking.Serial} change={change}/>
						</Grid>
						</React.Fragment>
						}
						<Grid item xs={12} sm={3} className="formName">フォーム</Grid>
						<Grid item xs={12} sm={9}>
							<ColInput id="FormCode" noName={true} dense={true} error={vali.errors.FormCode} type="menu" name="フォーム" notEmpty={true} list={states.forms} value={booking.FormCode} change={change} label={Inc.defaultFormName}/>
						</Grid>
						<Grid item xs={12} sm={3} className="formName">名前</Grid>
						<Grid item xs={12} sm={9}>
							<ColInput id="Name" noName={true} dense={true} error={vali.errors.Name} type="text" name="名前" placeholder="40文字以内" value={booking.Name} change={change}/>
						</Grid>
						<Grid item xs={12} sm={3} className="formName">名前（カナ）</Grid>
						<Grid item xs={12} sm={9}>
							<ColInput id="Kana" noName={true} dense={true} error={vali.errors.Kana} type="text" name="名前（カナ）" placeholder="40文字以内" value={booking.Kana} change={change}/>
						</Grid>
						<Grid item xs={12} sm={3} className="formName">メールアドレス</Grid>
						<Grid item xs={12} sm={9}>
							<ColInput id="Email" noName={true} dense={true} pattern="email" error={vali.errors.Email} type="text" placeholder="100文字以内（省略可）" name="メールアドレス" value={booking.Email} change={change}/>
						</Grid>
						<Grid item xs={12} sm={3} className="formName">メール確認</Grid>
						<Grid item xs={12} sm={9}>
							<ColInput id="Checked" noName={true} dense={true} useBool={true} type="radio" name="メール確認" value={booking.Checked} list={Inc.checked} change={change}/>
						</Grid>
						{props.stage.UseTel &&
						<React.Fragment>
						<Grid item xs={12} sm={3} className="formName">電話番号</Grid>
						<Grid item xs={12} sm={9}>
							<ColInput id="Tel" noName={true} dense={true} pattern="tel" error={vali.errors.Tel} type="text" placeholder="" name="電話番号" value={booking.Tel} change={change}/>
						</Grid>
						</React.Fragment>			
						}
						{props.stage.UseMemo &&
						<React.Fragment>
						<Grid item xs={12} sm={3} className="formName">備考欄</Grid>
						<Grid item xs={12} sm={9}>
							<ColInput id="Memo" noName={true} dense={true} type="textarea" name="備考欄" value={booking.Memo} change={change}/>
						</Grid>
						</React.Fragment>
						}
						{props.stage.UseEnq &&
						<React.Fragment>
						<Grid item xs={12} sm={3} className="formName">公演情報</Grid>
						<Grid item xs={12} sm={9}>
							<ColInput id="Contact" noName={true} dense={true} error={vali.errors.Contact} type="radio" list={Inc.contacts} name="公演情報" value={booking.Contact} change={change}/>
						</Grid>
						</React.Fragment>
						}
						<Grid item xs={12} sm={3} className="formName">支払方法</Grid>
						<Grid item xs={8} sm={5}>
							<ColInput id="PaymentTypeId" noName={true} dense={true} type="radio" list={Inc.paymentTypes} name="支払方法" value={booking.PaymentTypeId} change={change}/>
						</Grid>							
                        <Grid item xs={4} sm={4}>
                            {booking.PaymentTypeId==="9" &&
                            <ColInput name="支払方法その他" id="Payment" noName={true} dense={true} error={vali.errors.Payment} type="text" placeholder="10文字以内" value={booking.Payment} change={change}/>
							}
                        </Grid>

					</Grid>
				</div>
				</DialogContent>
				<DialogActions>
					<Button variant="text" color="default" onClick={props.close}>閉じる</Button>
					{props.booking && props.booking.Id>0 ? (
						<Button variant="contained" color="secondary" onClick={update}>この内容で更新する</Button>
					):(
						<Button variant="contained" color="secondary" onClick={add}>登録する</Button>
					)}
				</DialogActions>
		</Dialog>
	);
}

export default StageBookingInput;

import React,{useMemo} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
//import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DialogHeader from './DialogHeader';
import {Theme} from './Theme';

interface Props {
	code: string,
    close():void,
}

const SampleView = (props:Props)=> {
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));
	const csvdownload = useMemo(()=>{
		return (
			<React.Fragment>
				<h2>ＣＳＶファイル仕様<span>（予約のダウンロード）</span></h2>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>No.</TableCell>
							<TableCell>名称</TableCell>
							<TableCell>説明</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>1</TableCell>
							<TableCell>年</TableCell>
							<TableCell>開演日時の年</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>2</TableCell>
							<TableCell>月</TableCell>
							<TableCell>開演日時の月</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>3</TableCell>
							<TableCell>日</TableCell>
							<TableCell>開演日時の日</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>4</TableCell>
							<TableCell>時</TableCell>
							<TableCell>開演日時の時</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>5</TableCell>
							<TableCell>分</TableCell>
							<TableCell>開演日時の分</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>6</TableCell>
							<TableCell>チケット記号</TableCell>
							<TableCell>A～Eのいずれか</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>7</TableCell>
							<TableCell>チケット種類</TableCell>
							<TableCell></TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>8</TableCell>
							<TableCell>フォーム名</TableCell>
							<TableCell></TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>9</TableCell>
							<TableCell>枚数</TableCell>
							<TableCell>予約枚数</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>10</TableCell>
							<TableCell>単価</TableCell>
							<TableCell></TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>11</TableCell>
							<TableCell>請求額</TableCell>
							<TableCell></TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>12</TableCell>
							<TableCell>顧客名</TableCell>
							<TableCell></TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>13</TableCell>
							<TableCell>顧客名（カナ）</TableCell>
							<TableCell></TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>14</TableCell>
							<TableCell>メールアドレス</TableCell>
							<TableCell></TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>15</TableCell>
							<TableCell>メールアドレス確認</TableCell>
							<TableCell>未確認/確認済みのいずれか</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>16</TableCell>
							<TableCell>電話番号</TableCell>
							<TableCell></TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>17</TableCell>
							<TableCell>整理番号</TableCell>
							<TableCell></TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>18</TableCell>
							<TableCell>備考</TableCell>
							<TableCell></TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>19</TableCell>
							<TableCell>お知らせ希望</TableCell>
							<TableCell></TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>20</TableCell>
							<TableCell>支払方法</TableCell>
							<TableCell>当日精算/任意の文字列のいずれか</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>21</TableCell>
							<TableCell>登録方法</TableCell>
							<TableCell>管理/一括/顧客のいずれか</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>22</TableCell>
							<TableCell>キャンセル方法</TableCell>
							<TableCell>管理/顧客/（空欄）のいずれか<br/>キャンセルされてなければ空欄</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>23</TableCell>
							<TableCell>登録日時</TableCell>
							<TableCell>年月日と時分</TableCell>
						</TableRow>

					</TableBody>
				</Table>
				<div className="hint">
				※文字コード：UTF-8（カンマ区切り）<br/>
				</div>
			</React.Fragment>
		);
	},[]);

	const csvupload = useMemo(()=>{
		return (
			<React.Fragment>
				<h2>ＣＳＶファイル仕様<span>（予約のアップロード）</span></h2>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>No.</TableCell>
							<TableCell>名称</TableCell>
							<TableCell>説明</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>1</TableCell>
							<TableCell>年</TableCell>
							<TableCell>西暦　数字4桁</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>2</TableCell>
							<TableCell>月</TableCell>
							<TableCell>数字1～2桁</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>3</TableCell>
							<TableCell>日</TableCell>
							<TableCell>数字1～2桁</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>4</TableCell>
							<TableCell>時</TableCell>
							<TableCell>数字1～2桁</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>5</TableCell>
							<TableCell>分</TableCell>
							<TableCell>数字1～2桁</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>6</TableCell>
							<TableCell>チケット記号</TableCell>
							<TableCell>A～Eのいずれか</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>7</TableCell>
							<TableCell>フォーム名</TableCell>
							<TableCell>空欄の場合は代表フォーム</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>8</TableCell>
							<TableCell>枚数</TableCell>
							<TableCell>数字</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>9</TableCell>
							<TableCell>顧客名</TableCell>
							<TableCell>50文字以内</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>10</TableCell>
							<TableCell>顧客名（カナ）</TableCell>
							<TableCell>50文字以内　省略可</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>11</TableCell>
							<TableCell>メールアドレス</TableCell>
							<TableCell>100文字以内　省略可</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>12</TableCell>
							<TableCell>電話番号</TableCell>
							<TableCell>15文字以内　省略可</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>13</TableCell>
							<TableCell>備考</TableCell>
							<TableCell>文字数制限なし　省略可</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>14</TableCell>
							<TableCell>お知らせ希望</TableCell>
							<TableCell>希望する/希望しない/すでに届いている　省略可</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{textAlign:"center"}}>15</TableCell>
							<TableCell>支払方法</TableCell>
							<TableCell>当日精算/任意の文字列</TableCell>
						</TableRow>

					</TableBody>
				</Table>
				<div className="hint">
				※文字コード：UTF-8（カンマ区切り）<br/>
				※一度にアップロードできるのは100件まで<br/>
				</div>
			</React.Fragment>
		);
	},[]);

	const bookingmail = useMemo(()=>{
		return (
			<React.Fragment>
				<h2>予約通知メール<span>（サンプル）</span></h2>
				<div className="sampleMail">
<h3>メールのタイトル</h3>
ご予約をうけたまわりました<br/>
<br/>
<h3>メールの本文</h3>
※このメールは送信専用です。返信はできませんのでご注意ください。<br/>
<br/>
間宮林蔵様<br/>
<br/>
この度は 東海道四谷怪談 をご予約頂き、誠にありがとうございます。<br/>
下記の通りご予約を承りました。<br/>
<br/>
--------------------------------------------<br/>
ご予約内容<br/>
--------------------------------------------<br/>
名前：間宮林蔵（マミヤリンゾウ）<br/>
整理番号：なし<br/>
<br/>
2020年04月01日（水）　17:00開演<br/>
前売券 2800円×1枚<br/>
支払い金額：2800円<br/>
支払い方法：当日精算<br/>
公演情報：希望しない<br/>
<br/>
備考：<br/>
楽しみにしております。<br/>
<br/>
▼マイページ（予約内容の確認とキャンセル手続き）<br/>
https://r7ticket.jp/yotsuyakaidan/d/bpu650a6ntvgo6tc9ffg<br/>
※開演時間の10分前までキャンセルが可能です。<br/>
--------------------------------------------<br/>
<br/>
開場時間は開演の30分前です。<br/>
当日はご来場順のご入場となります。<br/>
<br/>
このメール内容は公演当日まで保管いただくようお願いたします。<br/>
<br/>
間宮林蔵様のご来場心よりお待ち申し上げております。<br/>
ご予約ありがとうございました。<br/>
<br/>
<br/>
不明な点がございましたら下記までご連絡ください。<br/>
<br/>
--------------------------------------------<br/>
お問い合わせ先<br/>
--------------------------------------------<br/>
事務局<br/>
TEL: 03-3333-3333<br/>
URL: https://www.tsuruya-nanboku.jp/yotsuya/<br/>
--------------------------------------------<br/>
				</div>
			</React.Fragment>
		);
	},[]);

	if( props.code==="" ){
		return (null);
	}

    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.code!==""}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				if( reason!=="backdropClick" ){
					props.close();
				}
			}}
			fullScreen={fullScreen}
        >
			<DialogHeader close={props.close}/>
			<DialogContent dividers>
                <div className="sampleRoot">
                    <div className="content">
						{props.code==="csvdownload" &&
							<div>{csvdownload}</div>
						}
						{props.code==="csvupload" &&
							<div>{csvupload}</div>
						}
						{props.code==="bookingmail" &&
							<div>{bookingmail}</div>
						}
                    </div>
                </div>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="default" onClick={props.close}>閉じる</Button>
			</DialogActions>
        </Dialog>
    );
}

export default SampleView;
